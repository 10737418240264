.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 2rem;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 0;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}

.filters {
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.filterBlock {
  width: 50%;
  min-width: 250px
}

.filters>div {
  margin: 7px 0;
}

.tableHeader > div {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow > div {
  color: #5b5c61;
}

.tableHeader > div, .tableRow > div {
  padding: 2px;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
  position: relative;
  cursor: pointer;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.firstTd {
  flex-basis: 5%;
  overflow: hidden;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 47%;
  overflow-wrap: anywhere;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.tableRow:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.tableRow:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.superuser {
  flex-basis: 25%;
}

.actions {
  flex-basis: 3%;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.fifthTd {
  flex-basis: 15%;
}

.sixthTd {
  flex-basis: 15%;
}

.pagination {
  width: 100%;
  max-width: 50%;
  margin: 2rem auto 0;
}

.radioWrap > div:first-child {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.radioBlock {
  width: 90%;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
}

.radioBlock label {
  cursor: pointer;
  flex-basis: 30%;
}

.radioBlock input {
  margin-right: 5px;
}

.closeBtn {
  display: none;
}

.modal {
  max-width: 1000px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: initial;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.modalBody div:first-child>div {
  margin-top: 8px;
}

.modalMain {}

.input {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

.goForward {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid grey;
  width: 135px;
  color: grey;
  padding-bottom: 3px;
}

.goBack {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid grey;
  width: 65px;
  color: grey;
  margin-bottom: 20px;
  padding: 0 4px 2px 0;
}

.goForward:hover,
.goBack:hover {
  color: brown;
  border-bottom: 1px solid brown;
}

.participalWrap {
  padding: 15px;
}

.tableWrap {
  max-height: 600px;
  overflow-y: auto;
}

.participalWrap h3 {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  color: rgb(88, 80, 80);
}

.tableWrap table {
  border-collapse: collapse;
  background-color: rgba(240, 248, 255, 0.486);
}

.tableWrap tbody tr:nth-child(odd) {
  background-color: rgba(247, 0, 255, 0.089);
}

.tableWrap tr:nth-child(even) {
  background-color: rgba(0, 255, 255, 0.089);

}

.tableWrap td {
  /* width: 20px; */
  background-color: rgba(240, 248, 255, 0.486);
  text-align: center;
  font-weight: 500;
}

.tableWrap td:hover {
  background-color: rgba(169, 170, 170, 0.229);
}

.tableWrap th {
  padding: 3px 5px;
}

.tableWrap thead th {
  background-color: rgb(158, 4, 56);
  color: rgb(245, 162, 107);
}

.tableWrap td,
.tableWrap th {
  border: 1px solid rgb(107, 105, 105);
  color: rgb(83, 83, 83);
}

  @media (max-width: 600px) {
    .filterBlock {
      width: 100%;
  }
}
@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    max-width: calc(100% - 2rem);
  }

  .newsList {
    padding: 0 .5rem 1rem;
  }

  .input {
    margin: 1rem auto 0;
  }

  .input label{
    font-size: .8rem;
  }
}

.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1rem 0;
  padding: .5rem;
  border: 1px solid rgba(0, 0, 0, .5);
  border-radius: .2rem;
}

.imagePDF {
  color: red;
  font-size: 30px;
  height: 30px;
  width: 30px;
  margin: 0 .5rem 0 0;
}

.titlePDF {
  font-size: 16px;
}

.linkpdf {
  display: contents;
  color: #000;
}

.toTrash {
  color: red;
  font-size: 25px;
  width: 25px;
  height: 25px;
  margin: 0 0 0 3rem;
  cursor: pointer;
}

.wrapperWar {
  display: flex;
  flex-wrap: wrap;
}

.wrapperWar > div {
  margin-right: .5rem;
}

.downloadExcel {
  background-color: rgb(117, 194, 181);
  width: fit-content;
  max-width: 190px;
  color: #fff;
  padding: .5rem 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .2rem;
  cursor: pointer;
}

.downloadExcel .excelLogo {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.excelLoad {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin .5s linear infinite;
  margin-left: .5rem;
}

.count {
  display: flex;
  /* justify-content: end; */
  align-items: center;
  width: 100px;
}

.count i {
  cursor: pointer;
  margin: 0 .1rem;
}

.count p {
  margin: 0 .2rem 0 0;
  font-weight: 500;
}

.categoryParamsBlock {
  border: 1px solid rgb(185 182 182);
  border-radius: .5rem;
  width: 90%;
  margin: .5rem 0 0 7px !important;
  min-height: 4rem;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.categoryParamsBlock > div {
  margin: .5rem;
}

@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.iconDownload {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
}

.count {
  display: flex;
}

.count p {
  line-height: 1.5rem;
}