.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: calc(80% - 24px);
}

.displayname > svg {
  margin-left: .3rem;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.superuser {
  flex-basis: 20%;
  cursor: pointer;
}

.superuser > svg {
  margin-left: .3rem;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}

.goForward {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid grey;
  width: 300px;
  color: grey;
  padding-bottom: 3px;
}

.goForward:hover {
  color: rgb(78, 75, 75);
}

/*==============================MODAL===================================*/

.closeBtn {
  display: none;
}

.modal {
  max-width: 1200px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
  display: block;
  box-shadow: none;
}

.modal2 {
  max-width: 980px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  box-shadow: none;
}

.modalBody {
  width: 100%;
  padding: 15px 25px 30px;
}

.modalMain {}

.input {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.agesBlock {
  display: flex;
  gap: 1rem;
}

.agesBlock > input {
  width: 50px;
}

.mapBlock {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  color: #6b6b6b;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.modalBody p {
  margin: 0px 0 5px;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

.firstBlock {
  display: flex;
  justify-content: space-between;
}

.firstBlock__left {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 15px;
}

.firstBlock__left select {
  border: 1px solid #cecece;
  padding: 2px;
}

.stepWrapper {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepTextNumber {
  width: 50px;
  margin-bottom: 0.5rem;
}

.stepTitle {
  min-width: 100px;
  width: 23%;
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.stepText {
  display: block;
  min-width: 100px;
  width: 50%;
}

.stepResult {
  min-width: 60px;
  width: 12%;
}

.stepsWrapper {
  overflow: auto;
  max-height: 300px;
}

.stepsWrapper :not(:first-child) p,
.stepsWrapper :not(:first-child) span {
  font-size: 14px;
}

.iconText {
  width: 30px;
  display: block;
  opacity: 0;
}

.editStep,
.deleteStep {
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  padding: .2rem;
  margin-left: .3rem;
}

.deleteStep:hover {
  color: red;
  transition: .2s;
}

.editStep:hover {
  color: rgb(117, 194, 181);
  transition: .2s;
}

.stepNumber {
  width: 80px;
  display: flex;
  align-items: center;
}

.stepNumber span {
  margin-right: .5rem;
  color: #989898;
}

.stepSelect {
  padding: .5rem;
}

.stepTitleBig {
  font-size: 12px;
  color: #989898;
  padding-top: 2rem;
  display: block;
}

.addStepButton {
  background-color: rgb(117, 194, 181);
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
  display: block;
  width: 100%;
  max-width: 138px;
}

.stepsWrapper::-webkit-scrollbar {
  width: .5rem;
}

.stepsWrapper::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 1rem;
}

.stepsWrapper::-webkit-scrollbar-thumb {
  background-color: rgb(117, 194, 181);
  border-radius: 1rem;
}

.imageBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.imageBlock select {
  color: #000;
  font-style: normal;
  margin: 0 0 auto auto;
  width: 100%;
}

.imageBlock label {
  width: 100%;
  margin: 1rem auto;
  text-align: center;
}

.imageBlock button,
.inputButton {
  margin-top: 3px;
  background-color: rgba(83, 85, 85, 0.082);
  padding: 5px 15px;
  font-size: 13px;
  border: 1px solid rgb(177, 171, 171);
  border-radius: 5px;
  color: rgb(119, 119, 119);
  cursor: pointer;
}

.imageBlock button:hover,
.inputButton:hover {
  background-color: rgba(83, 85, 85, 0.26);
  transition: all 0.2s ease-in;
}

.image {
  border: 1px solid rgb(177, 171, 171);
  height: 180px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

.task__label {
  color: #989898;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
  width: 100%;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.tableWrap {
  margin-top: 20px;
}

.tableWrap table,
.realizBlock table {
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.2em;
  width: 100%;
  font-weight: 500;
}

.tableWrap tr,
.realizBlock tr {
  background-color: rgba(7, 248, 135, 0.073);
  color: rgb(1, 1, 66);
}

.tableWrap tr:nth-child(even),
.realizBlock tr:nth-child(even) {
  background-color: rgba(255, 166, 0, 0.100);
  color: rgb(87, 12, 12);
}

.tableWrap tr:hover {
  cursor: pointer;
  opacity: 0.8;
}

.tableWrap td,
.realizBlock td {
  font-size: 16px;
  padding: 5px 2px;
}

.tableWrap select {
  font-size: 14px;
  background-color: transparent;
  color: rgb(49, 48, 48);
  cursor: pointer;
  border: none;
  outline: none;
  font-style: italic;
  font-weight: 500;
}

.tableWrap tr td:first-child,
.realizBlock tr td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  text-align: center;
}

.tableWrap tr td:last-child,
.realizBlock tr td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.downloadExcel {
  background-color: rgb(117, 194, 181);
  width: 160px;
  color: #fff;
  padding: .5rem 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .2rem;
  cursor: pointer;
}

.downloadExcel i {
  width: 1rem;
  height: 1rem;
}

.realizBlock {
  min-height: 200px;
  height: 500px;
  overflow-y: auto;
}

.realizBlock table {
  margin-top: -10px;
}

.realizBlock table tr td:first-child {
  width: 30px;
  text-align: center;
}

.realizBlock table tr td:last-child {
  width: 200px;
  text-align: center;
}

.realizBlock img {
  max-width: 100%;
}

.realizBlock thead tr {
  background-color: white;
  position: sticky;
  top: 0;
}

.realizBlock tr {
  margin: 3px 0;
}

.realizBlock thead tr th {
  padding: 5px 0;
}

.timeBlock {
  display: flex;
  gap: 1rem;
}

.timeBlock input {
  border-radius: 1px;
  border: 1px solid #cecece;
  outline: none;
  padding: 3px;
}

.filter:focus {
  border: 1px solid #800f0f;
}

.regionDepartmentsList, .otherDepartmentsList {
  max-height: 200px;
  margin-bottom: 30px;
  overflow: auto;
  display: flex;
}

.regionDepartmentsList > div, .otherDepartmentsList > div {
  width: 50%;
}

.secondBlock {
  margin-top: 1rem;
}

.secondBlock > select {
  border: 1px solid #cecece;
  padding: 2px;
  margin-bottom: 1rem;
}

.searchField {
  border: 1px solid hsl(0, 0%, 80%);
  color: grey;
  padding: .2rem;
  border-radius: .2rem;
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}

@media (max-width: 1100px) {

  .wrap {
    top: 10px !important;
    position: fixed;
    max-width: 90vw !important;
    width: 100%;
    background-color: #fff;
    z-index: 999999999999;
  }
}

@media (max-width: 768px) {

  .itemsCount {
    position: static;
    bottom: auto;
    left: auto;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stepTitle {
    min-width: 100px;
    width: 100%;
    margin-left: .5rem;
  }

  .stepText {
    display: block;
    min-width: 100px;
    width: 100%;
  }

  .stepResult {
    min-width: 60px;
    width: 100%;
  }

  .stepWrapper:first-child {
    display: none;
  }

  .stepTitle {
    margin-left: 0;
  }

  .stepWrapper {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #989898;
  }

  .firstBlock__left {
    width: 100%;
  }

  .firstBlock {
    flex-direction: column;
  }
}

.wrap {
  top: 5vh;
  position: fixed;
  max-width: 980px;
  width: 100%;
  background-color: #fff;
  z-index: 999999999999;
}