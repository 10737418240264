@font-face {
    font-family: 'FuturaFuturisBlackC';
    src: local('FuturaFuturisBlackC'), url('../fonts/FuturaFuturisBlackC.ttf') format('truetype');
  }

@font-face {
font-family: 'FuturaFuturisCBold';
src: local('FuturaFuturisCBold'), url('../fonts/FuturaFuturisC-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCBoldItalic';
    src: local('FuturaFuturisCBoldItalic'), url('../fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCItalic';
    src: local('FuturaFuturisCItalic'), url('../fonts/FuturaFuturisC-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisC';
    src: local('FuturaFuturisC'), url('../fonts/FuturaFuturisC.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCameoExtraBoldC';
    src: local('FuturaFuturisCameoExtraBoldC'), url('../fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.content {
    width: 100%;
    background-image: url('../img/backContent.png');
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
    position: relative;
}

.title {
    font-size: 4vw;
    padding: 4vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
}

.postTitle {
    font-size: 3vw;
    padding: 4vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
}

.item {
    font-size: 4vw;
    background-color: #fff;
    border-radius: 50px;
    padding: 3vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
    margin: 2vw 4vw;
}

.buttonBack {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    width: 10vw;
    z-index: 3;
}