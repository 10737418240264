.button {
    display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 20px;
  /* height: 20px; */
  border-radius: 6px;
  border: none;
  background-color: #cc0606;
  background: linear-gradient(45deg, #cc0606, #a50202);
  color: #fffefe;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.button:hover, .button:disabled {
  opacity: 0.8;
  box-shadow: 0 0 5px grey;
}