
.container {
    /* height: 500px; */
    max-width: 1100px;
    width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
    width: 100%;
    min-height: 523px;
    height: calc(100% - 34px);
    padding: 0px 14px 70px;
    background-color: #fff;
    position: relative;
}

.newsListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 4px;
}

.tableHeader {
    display: flex;
    align-items: center;
    min-height: 36px;
    border-bottom: 1px solid #c4c4c4;
  }
  


  .tableHeader div:first-child {
    color: #4c4c4c;
    font-size: 15px;
    font-weight: 700;
  }

  .tableRow {
    padding: 3px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d5d5;
  }

  .tableRow:hover {
    background-color: #fcf6f6b4;
  }
  
  .displayname {
    color: #5b5c61;
    cursor: pointer;
    width: 100%;
  }

  .displayname span {
    position: relative;
    margin-left: 5px;
  }

  .displayname span::after {
    position: absolute;
    bottom: 5px;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: rgb(75, 112, 109);
    opacity: 0;
    transition: all 0.2s ease-in;
  }

  .displayname:hover span {
    color: rgb(16, 86, 80);
    font-weight: 500;
  }
  
  .displayname:hover span::after {
    opacity: 1;
    transform: translateY(5px);
  }
  
  .username {
    flex-basis: 40%;
  }
  
  .superuser {
    flex-basis: 10%;
  }
  
  .actions {
  }

  .actions svg {
    color: rgb(204, 20, 20);
    cursor: pointer;
    font-size: 24px;
  }

  .actions svg:hover {
    color: #ff0000;
  }
  .deleteIcon {
    width: 20px;
    cursor: pointer;
  }

  .pagination {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .controls {
    position: absolute;
    bottom: 25px;
    left: 25px;
    display: flex;
    align-items: center;
  }

  .sortBlock {
    border: 1px solid rgb(187, 184, 184);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }

  .sortBlock:active {
    background-color: rgb(250, 226, 192);
  }
  
  .sortActive {
    background-color: rgb(208, 253, 238);
    border-color: rgb(148, 146, 146);
  }
  
  .sortBlock svg {
    font-size: 20px;
    color: rgb(187, 184, 184);
  }

  .sortActive svg {
    color: rgb(148, 146, 146);
  }

  .sortBlock:hover {
    transform: scale(1.05);
  }
  
  .itemsCount {
    margin-left: 10px;
    user-select: none;
  }
  
  .itemsCount select {
    color: grey;
    cursor: pointer;
    outline: none;
    border-radius: 3px;
  }
  
  .itemsCount span {
    margin-left: 5px;
    color: grey;
  }

  /*==============================MODAL===================================*/

  .closeBtn {
    display: none;
  }
  
  .modal {
    max-width: 780px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
  }

  .modalBody {
      width: 100%;
      padding: 15px 15px 30px;
  }

  .modalMain {

  }

.input {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  font-size: 16px;
  padding: .2rem;
  font-weight: 400;
}

.mapBlock {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #6b6b6b;
}

.select {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
}

.filesBlock {
    margin-top: 15px;
    width: 90%;
    word-break: break-all;
}

.fileItem {
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
    width: 90%;
}

.fileItem + .fileItem {
    margin-top: 5px;
}

.imgBlock {
    margin: 4px 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.imgBlock img {
    max-width: 100%;
    max-height: 100%;
}

  .modalButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .modalButtons button {
    width: 125px;
    height: 30px;
    margin: 0 20px;
    font-size: 16px;
    border-radius: 25px;
  }

  .firstBlock {
    display: flex;
    justify-content: space-between;
}

.firstBlockInner {
  width: 100%;
  margin-right: 1rem;
  min-width: 250px;
  max-width: 500px;
}

.imageBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageBlock button, .inputButton {
    margin-top: 3px;
    background-color: rgba(83, 85, 85, 0.082);
    padding: 5px 15px;
    font-size: 13px;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
}

.wrapperBut {
  text-align: center;
  margin:  1rem 0 0 0;
  width: 100%;
  display: block;
}

.imageBlock button:hover, .inputButton:hover {
    background-color: rgba(83, 85, 85, 0.26);
    transition: all 0.2s ease-in;
}

.image {
    border: 1px solid rgb(177, 171, 171);
    height: 180px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.descriptionTitle {
  font-size: 17px;
  font-weight: 500;
  padding: 0 0 4px 0;
  display: block;
}

.image img {
    max-width: 100%;
    max-height: 100%;
}


@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}

@media (max-width: 625px) {
  .firstBlock {
    flex-wrap: wrap;
  }

  .firstBlockInner {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .imageBlock {
    margin: 0 0 1rem 0;
    width: 100%;
  }
}

.wrapperSliderEquipment {
  width: 100%;
  max-width: 300px;
}

.equipment__small .photoWrapper {
  width: 100px;
  height: 100px;
  object-fit: contain;
}


.deletePhoto {
  position: absolute;
  right: 0;
  top: 0;
  color: red;
  font-size: 28px;
  z-index: 9;
}

.wrapperSliderEquipmentBig img {
  height: 300px;
  object-fit: contain;
}

.wrapperSliderEquipmentSmall img {
  height: 100px;
  object-fit: contain;
}

.load {
  position: relative;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

.switch input {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.switch input:disabled + .slider {
  opacity: 0.5;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: brown;
}

input:focus + .slider {
  box-shadow: 0 0 1px brown;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}