.wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question {
    background-color: rgba(117, 117, 117, 0.582);
    border: 1px solid gray;
    width: 70%;
    margin-top: 50px;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 24px;
}

.mapWrap {
    margin-top: 50px;
    width: 70%;
    height: auto;
    position: relative;
}

.mapWrap img {
    width: 100%;
    
}

.map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(1000, 0.1%);
    grid-template-rows: repeat(1000, 0.1%);
}


.item1, .item2, .item3, .item4, .item5 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 4px;
    font-weight: 800;
    color: rgb(0, 0, 0);
  }
  
  .item1 img, .item2 img, .item3 img, .item4 img, .item5 img{
    transition: opacity 0.5s ease-in;
    max-height: 60%;
    max-width: 60%;
  }
  
  .item1 {
    grid-column: 84 / 204;
    grid-row: 450 / 650;
  }
  
  .item2 {
    grid-column: 200 / 350;
    grid-row: 410 / 580;
  }
  
  .item3 {
    grid-column: 40 / 150;
    grid-row: 700 / 900;
  }
  
  .item4 {
    grid-column: 320 / 600;
    grid-row: 400 / 950;
  }
  
  .item5 {
    grid-column: 600 / 850;
    grid-row: 185 / 800;
  }

.answer {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
}

.answer div {
  background-color: rgba(117, 117, 117, 0.582);
  border: 1px solid gray;
  border-radius: 10px;
  width: 200px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;   
  cursor: pointer; 
}

.answer div:hover {
  outline: 1px solid gray;
  color:rgb(236, 235, 226);
  transform: scale(1.05);
  transition: all 0.2s ease-in;
}