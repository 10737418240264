.container {
    width: 100%;
    min-height: 55vh;
    padding: 1rem 2rem 2rem;
}

.container table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
  width: 100%;
  font-weight: 500;
}

.container tr {
  background-color: rgba(7, 248, 135, 0.073);
  color: rgb(1, 1, 66);
}

.container tr:nth-child(even) {
  background-color: rgba(255, 166, 0, 0.100);
  color: rgb(87, 12, 12);
}

.container tr:hover {
  cursor: pointer;
  opacity: 0.8;
}

.container td {
  font-size: 16px;
  padding: 5px 2px;
}

.container table select {
  font-size: 14px;
  background-color: transparent;
  color: rgb(49, 48, 48);
  cursor: pointer;
  border: none;
  outline: none;
  font-style: italic;
  font-weight: 500;
}

.container td:nth-child(2),
.container td:nth-child(3),
.container td:nth-child(4) {
    padding-left: 5px;
}
.container tr td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  text-align: center;
}

.container tr td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
}

.goBack {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid grey;
    width: 65px;
    color: grey;
    margin-bottom: 20px;
    padding: 0 4px 2px 0;
}

.goBack:hover {
    color: brown;
    border-bottom: 1px solid brown;
}