.searchBlock {
    width: 200px;
    height: 28px;
    position: relative;
    border: 1px solid rgb(7, 109, 95);
    font-size: 24px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: rgb(80, 78, 78);
}

.searchBlock svg:first-child {
    color: rgb(38, 80, 74);
}

.searchBlock svg:last-child {
    font-size: 28px;
    cursor: pointer;
}

.searchBlock input {
    padding-left: 3px;
    font-size: 16px;
    width: 150px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(211, 211, 211);
    cursor: pointer;
    color: rgb(77, 75, 75);
    padding:2px 0;
}