.wrap {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #7d2e2d;
}

.wrap div {
    margin-left: .5rem;
    font-size: 18px;
    color: grey;
}