.profileBlock {
  margin-top: 20px;
  position: relative;
  width: fit-content;
}

.profileBlock > div:first-child {
  color: #989898;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 5px;
}

.profilleBlockIcons {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(94, 9, 9);
  position: absolute;
  top: -7px;
  right: -50px;
}

.profileName {
  font-style: italic;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.profileName:hover {
  color: rgb(94, 9, 9);
}

.popup {
  width: 400px;
  padding: 10px;
  position: absolute;
  background-color: rgb(247 247 247);
  z-index: 999999999;
  top: 50px;
  border: 1px solid rgb(185 182 182);
  border-radius: .4rem;
}

.popupLabel {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.popupProfiles {
  width: 380px;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid rgb(185 182 182);
  overflow: auto;
  padding: 5px;
  border-radius: .4rem;
  background-color: white;
  position: relative;
}

.popupProfiles p {
  font-size: 15px;
  margin: 5px 0;
  cursor: pointer;
}

.popupProfiles p:hover {
  color: rgb(128 15 15);
}