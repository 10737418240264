.inputFile {
    margin: 1rem auto;
    display: block;
    width: fit-content;
    background-color: rgba(83, 85, 85, 0.082);
    padding: .5rem 1rem;
    font-size: .9rem;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
    font-weight: 500;
}

.inputFile:hover {
    background-color: rgba(83, 85, 85, 0.26);
    color: rgb(255, 255, 255);
    transition: all 0.2s ease-in;
    border-color: rgb(204, 206, 206);
}

.inputFile input[type="file"] {
    display: none;
}


