.paramsWrapper > div {
  margin: 20px 0;
}

.paramsWrapper > div:first-child {
  margin: 0;
  color: rgb(80, 80, 80);
}

.description > textarea {
  min-width: 60%;
  min-height: 100px;
  outline: none;
  border-radius: 5px;
  background-color: #c4c4c41f;
}

.paramsWrapper table {
  border-collapse: collapse;
  background-color: rgba(240, 248, 255, 0.486);
  width: 90%;
  user-select: none;
  position: relative;
}

.paramsWrapper tr {
  position: relative;
}

.paramsWrapper table > div {
  position: absolute;
  bottom: 0px;
  right: -60px;
  padding: 1px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(88, 87, 87);
  background-color: #f8fcff;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.paramsWrapper table > div:hover {
  opacity: 0.7;
}

.paramsWrapper tr > div {
  position: absolute;
  bottom: 0;
  right: -30px;
  padding: 1px;
  border: 1px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(88, 87, 87);
  background-color: #f8fcff;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.paramsWrapper tr > div:hover {
  opacity: 0.7;
}

.paramsWrapper thead th {
  background-color: rgb(148, 5, 53);
  color: rgb(245, 162, 107);
}

.paramsWrapper th, .paramsWrapper td {
  font-size: 14px;
  border: 1px solid grey;
  padding: 2px 0;
}

.paramsWrapper td {
  word-break: break-all;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.filesListEmpty {
  color: #747475;
  display: flex;
  align-items: center;
  margin: 10px;
}

.filesListEmpty svg {
  font-size: 50px;
}

.filesList {
  display: flex;
  flex-wrap: wrap;
}

.filesList a {
  text-decoration: none;
}

.fileItem {
  display: flex;
  padding: 3px 2px 3px 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.fileItem svg {
  font-size: 22px;
  color: red;
  cursor: pointer;
}

.fileItem svg:hover {
  color: rgb(201, 9, 9);
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.confirmField input, .confirmField textarea {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 5px 2px 2px;
  outline: none;
  /* border: none; */
  min-width: 100%;
  max-width: 100%;
  background: #c4c4c41f;
}

.confirmField textarea {
  height: fit-content;
  resize: vertical;
}


.datePickerField {
  max-width: 100px !important;
}

.linksWrap {
  width: 100%;
  height: 100%;
  max-width: 300px;
  min-height: 100px;
  border: 1px solid grey;
  border-radius: 5px;
  background: #c4c4c41f;
  cursor: pointer;
  position: relative;
}

.linksPopUp {
  position: absolute;
  top: 100%;
  left: 100%;
  width: 300px;
  height: auto;
  background: #f7f5f5;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 5;
}

.linksPopUp div {
  margin: 5px 0;
  color: rgb(83, 1, 1);
}

.linksPopUp div:hover {
  font-weight: 500;
  text-decoration: underline;
}