.inputWrapper {
    border-bottom: 1px dotted grey;
    margin: 0 10px;
    width: 100%;
    position: relative;
    padding: 2px;
}

.validDescr {
    position: absolute;
    top: -20px;
    font-size: 13px;
    width: 500px;
    color: red;
}

.notValid {
    border: 1px solid rgba(255, 0, 0, 0.514);
    border-radius: 3px;
}

.title {
    font-size: 12px;
    color: rgb(148, 142, 134);
}

.input {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    color: rgb(2, 2, 114);
}


.block {
    margin: 10px 0;
    width: 100%;
    min-height: 20px;
    font-size: 18px;
    font-weight: 500;
    /* font-style: italic; */
    color: rgb(2, 2, 114);
}


@media (max-width: 1024px) {
    .inputWrapper {
        margin: 1rem 0;
    }
}

.pickerProfile  {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    color: rgb(2, 2, 114);
    user-select: none;
}