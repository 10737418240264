.title {
    text-align: center;
    margin: 1rem 0;
    font-weight: 500;
    font-size: 2rem;
}

.progress {
    width: 100%;
    margin: .5rem 0 1rem 0;
    height: 20px;
    background-color: #75c2b5;
    border-radius: 100px;
    overflow: hidden;
}

.progress::-webkit-progress-value { 
    background: #75c2b5; 
    transition: all 1s ease-in-out;
}
.progress::-moz-progress-bar { 
    background: #75c2b5; 
    transition: all 1s ease-in-out;
}
.progress { 
    color: #75c2b5; 
    transition: all 1s ease-in-out;
}
.button {
    cursor: pointer;
    padding: .5rem;
    border-radius: 5px;
    border: 1px solid #75c2b5;
    background-color: #75c2b5;
    color: #fff;
    font-weight: 500;
}

.fileInput {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progressText {
    margin: 1rem 0 0 0;
    font-weight: 500;
    text-align: center;
}