.wrapper {
    max-width: 1400px;
    width: 90vw;
    overflow: hidden;
}

.wrapper h1 {
    color: rgb(116, 11, 11);
    text-align: center;
}

.year {
    margin: 50px;
}

.year div {
    color: rgb(116, 11, 11);
    font-size: 14px;
    font-weight: 500;
}

.year select {
    padding: 3px 2px;
    font-size: 18px;
    font-weight: 500;
    background-color: transparent;  
    border: none;
    outline: none;
    color: rgb(83, 83, 83);
    cursor: pointer;
    transition: all 0.2s ease-in;
}
    
.year select:hover, .year select:focus {
    transform: scale(1.1);
}

.tableWrap {
    max-width: fit-content;
    overflow-x: auto;
    margin: auto;
    position: relative;
    white-space: nowrap;
    border: 2px solid rgb(20, 94, 70);
    border-radius: 3px;
}

.tableWrap::-webkit-scrollbar {
    height: 8px;
}

.tableWrap::-webkit-scrollbar-thumb {
    background-color: rgb(250, 195, 133);
}

.table {
    border-collapse: collapse;
    table-layout:fixed !important;
}

.table tr {
    height: 38px;
}

.first {
    position: sticky;
    width: 150px !important;
    overflow: hidden !important;
    word-break: break-all !important;
    left: 0px;
    background-color: aliceblue !important;
}

.table th, .table td{
    color: rgb(88, 7, 7);
    border: 1px solid rgb(88, 88, 88);
    padding: 3px;
}

.table td {
    min-width: 32px;
    cursor: pointer;
    background-color: rgba(127, 255, 212, 0.137);
    text-align: center;
}

.table th {
    background-color: rgba(240, 248, 255, 0.733);
}

.table input {
    padding: 0 !important;
    display: inline-block;
    max-width: 50px;
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(88, 7, 7);
    height: 100%;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .first {
        position: relative;
    }
}