.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.wrapper > h3 {
    color: rgb(80 79 79);
}

.wrapper > div + h3 {
    margin-top: 3rem;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: end;
    max-width: 80%;
}

.reportButtons {
    position: absolute;
    top: 10px;
    right: 5%;
    display: flex;
}

.reportButtons > svg {
    cursor: pointer;
    transition: .2s;
}

.reportButtons > svg:hover {
    transform: scale(1.05);
}

.reportTable {
    border-collapse: collapse;
    width: 60%;
    margin-top: 1rem;
    font-size: 15px;
}

.reportTable thead th {
    cursor: pointer;
}

.reportTable tbody tr:hover {
    background-color: rgba(128, 128, 128, 0.582);
}


.reportTable td, .reportTable th {
    border: 1px solid rgb(80, 79, 79);
    padding: 3px;
    color: rgb(80, 79, 79);
}

.reportTable th, .reportTable tr:last-child td {
    background-color: rgba(240, 248, 255, 0.693);
}

.reportTable td {
    text-align: center;
    background-color: rgba(162, 250, 221, 0.596);
}
