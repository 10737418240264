.container {
    width: 80%;
    margin: 0 auto;
}

.titleBlock {
    margin: 0 auto 1rem;
    color: #6a1919;
    width: fit-content;
    position: relative;
}

.calendarBlock {
    height: 55vh;
    width: 100%;
    padding: .6rem;
    background-color: white;
    background: linear-gradient(135deg,rgba(255, 255, 255, 0), #c8e5dece, rgba(255, 255, 255, 0));
    box-shadow: 0 0 100px white;
    border-radius: .3rem;
}

.dateCellWrapper {
    width: 100%;
    /* height: 300px; */
    background-color: antiquewhite;
}

.title {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}

.modal {
    max-width: 720px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    /* overflow: hidden; */
}

.modalBody {
    width: 100%;
    padding: 15px 15px 30px;
    position: relative;
}

.inputsBlock > * + * {
    margin-top: .8rem;
}

.textarea {
    resize: none;
    height: 10rem;
    outline: none;
}

.colorPicker {
    padding-right: 3px !important;
}

.modalButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.modalButtons button {
    width: 125px;
    height: 30px;
    margin: 0 20px;
    font-size: 16px;
    border-radius: 25px;
}

.eventBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
}

.eventBlock > div {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2em; 
    white-space: nowrap;

}

.eventBlock > svg:hover {
    transform: scale(1.1);
    transition: .2s;
}

.day_weekend {
    color: #e24c4c;
}