.input {

}

.input > div:first-child {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.input textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(185 182 182);
  color: #313030;
  padding: 1px 0 1px 3px;
  font-weight: 400;
}

.validate {
  font-size: .8rem;
  font-weight: 400;
  color: red;
  margin: -0.3rem 0 0.2rem 0;
}