.filterItem, .filterItemCountry {
    position: relative;
    width: 100%;
}

.filterItem + .filterItem {
    border-top: 1px solid rgba(128, 128, 128, 0.377);
}

.filterItemCountry {
    border-bottom: 1px solid rgba(128, 128, 128, 0.377);
}

.filterTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 400;
    color: grey;
    padding: 10px 0;
    cursor: pointer;
}

.filterName {
    font-size: 16px;
    margin-left: 1rem;
    user-select: none;
}

.filterNameActived {
    font-size: 16px;
    color: #700000;
    font-weight: 600;
    margin-left: 1rem;
}

.filterTitle div {
    display: flex;
    align-items: center;
}

.filterTitle svg {
    margin: 0;
}

.selectBlock {
    overflow: auto;
    max-height: 200px;
}

.selectBlock::-webkit-scrollbar {
    background-color: #eee;
    width: .5rem;
  }
  
.selectBlock::-webkit-scrollbar-thumb {
    background-color: rgb(95, 95, 95);
    border-radius: 1rem;
}

.inputBlock input{
    width: 100%;
    margin: 0 0 1rem 0;
    font-size: 16px;
    padding: .2rem;
    border: 1px solid #c8dedc;
}

input  {
    outline: none;
}

.itemList {
    position: absolute;
    top: 80px;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid rgb(215 215 215);
    z-index: 9999;
}

.itemList::-webkit-scrollbar {
    width: 10px;
}

.itemList::-webkit-scrollbar-thumb {
    background-color: rgba(133, 17, 17, 0.719);
    border-radius: 5px;
}

.itemList div {
    padding: 3px;
    cursor: pointer;    
}

.itemList div:hover {
    background-color: rgb(200 227 222);
    transition: .3s;
}

.selectedBlock {
    display: flex;
    flex-wrap: wrap;
}

.selectedBlock div {
    border: 1px solid #c8dedc;
    border-radius: 6px;
    padding: 2px 6px;
    margin: 0 .5rem .5rem 0;
    color: rgb(141 191 186);
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.833);
    font-size: 13px;
}

.selectedBlock svg {
    margin-top: 3px;
    margin-left: 3px;
    cursor: pointer;
    color: #c8e3de !important;
}

.selectItem {
    color: rgb(124, 3, 3);
    font-size: 19px;
    padding: 3px 0 3px 5px;
}

.selectItem input {
    cursor: pointer;
    margin-right: 10px;
}

.selectItem label {
    cursor: pointer;
}

.lastNameBlock input {
    width: 100%;
    margin: 0 0 1rem 0;
    font-size: 16px;
    padding: .2rem;
    border: 1px solid #c8dedc;
}

.datesBlock {
    margin-bottom: 10px;
    color: rgb(136, 10, 10);
}

.datesBlock input {
    font-size: 18px;
    width: 114px;
    border: 1px solid #c8dedc;
}

.arrow {
    transform: rotate(0deg);
    transition: .2s ease-in-out;
    width: 24px;
    height: 24px;
}

.arrowActive {
    transition: .2s ease-in-out;
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
}

i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.7rem;
    height: 1.7rem;
    font-size: 1.5rem;
}