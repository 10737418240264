.wrapper {
    margin: .5rem 0;
}

.wrapper :hover {
    cursor: pointer;
}

.checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    width: fit-content;
    color: rgb(85, 83, 83);
    font-size: 14px;
}

.checkbox input {
    display: none;
}

.fakeBox {
    border: 2px solid #d7001b;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #d7001b;
}

.checkbox input:disabled + .fakeBox {
    opacity: 0.5;
}

.icon {
    width: 24px;
    height: 24px;
}

.span {
    display: block;
    margin-top: 1px;
    font-size: 13px;
}