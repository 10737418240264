.chart {
    height: 120px;
    width: 110%;
    background-color: #f09522;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.box {
    height: 120px;
    background-color: #af0300;   
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    font-size: 30px !important;
    font-weight: 700;
    animation: box 0.8s ease-in;
}

.text {
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 30px;
    color: white !important;
    animation: box 0.8s ease-in;
}

.text2 {
    position: absolute;
    bottom: 3px;
    left: 5px;
    font-size: 50px;
    color: white !important;
    animation: box 0.8s ease-in;
}

.text3 {
    position: absolute;
    bottom: 10px;
    right: 5px;
    font-size: 20px;
    color: white !important;
    animation: box 0.8s ease-in;
}

@keyframes box {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 768px) {
    .chart {
        width: 100%;
    }
    .text3 {
        font-size: 1rem;
    }
}