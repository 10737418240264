.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navigation {
    display: flex;
}

.number {
    color: rgb(116, 11, 11);
    font-weight: 800;
    font-size: 26px;
}

.left {
    padding: 10px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    min-width: 80px;
    border: 2px solid rgb(116, 11, 11);
    background-color: rgba(255, 255, 255, 0.575);
    color: rgb(116, 11, 11);
    font-weight: 800;
}

.right {
    padding: 10px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    min-width: 80px;
    border: 2px solid rgb(116, 11, 11);
    background-color: rgba(255, 255, 255, 0.575);
    color: rgb(116, 11, 11);
    font-weight: 800;

}

.left:disabled, .right:disabled {
    opacity: 0.7;
}

.descriptionWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.39);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}

.description img {
    max-height: 100px;
}

.description div {
margin: 10px 0;
}

.time {
    font-weight: 800;
}

.description button {
    padding: 3px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 5px;
    background-color: red;
    outline: none;
    border: 1px solid rgb(148, 9, 9);
    color: whitesmoke;
}