.aboutWrapper {
    width: 100%;
    max-width: 1100px;
    height: auto;
}

.aboutTitle {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}

.quillWrapper {
    padding: 1rem 2rem;
    background-color: #fff;
}

.title {
    color: #989898;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
}

.inputBlock {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.inputBlock input {
    margin: 1rem;
    width: 100%;
    font-size: 1rem;
    padding: .2rem;
}

.buttons {
    display: flex;
    justify-content: center;
    margin: 2rem auto;
}

.button {
    width: 133px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: none;
    background-color: #d81717;
    margin: 0 23px;
    color: #ffffff;
    font-family: Calibri;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.equipment__small .photoWrapper {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

.deletePhoto {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-size: 28px;
    z-index: 9;
}

.imageBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapperSliderAbout {
    width: 100%;
    max-width: 400px;
}

.titleSlider {
    color: #989898;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 2rem;
    display: block;
}

.paper {
    max-width: 400px;
    width: 100%;
    height: 300px;
    background-color: rgb(223, 219, 219);
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10rem;
}

@media (max-width: 768px) {
    .aboutTitle {
        font-size: 17px;
    }

    .quillWrapper {
        padding: 1rem .5rem;
    }
}