.wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.question {
    background-color: rgba(117, 117, 117, 0.582);
    border: 1px solid gray;
    width: 70%;
    margin-top: 50px;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 24px;
}

.answer {
    margin: 40px 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.answerItem {
    border-radius: 10px;
}

.answerItem div {
    width: 200px;
    height: 200px;
    border: 1px solid gray;
}

.answerItem div:first-child {
    height: 100px;
    background-color: rgba(117, 117, 117, 0.582);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.answerItem div:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.answerItem img {
    max-height: 180px;
    max-width: 180px;
}

.variants {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.variantsItem {
    height: 180px;
    cursor: pointer;
}

.variantsItem img {
    max-height: 100%;
}