.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}