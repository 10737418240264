.select {
  position: relative;
}

.select div {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.validate {
  /* position: absolute; */
  top: 0;
  right: 10%;
  color: red !important;
}

.select select {
  width: 90%;
  border-radius: .4rem;
  border: 1px solid rgb(185 182 182);
  color: #313030;
  padding-left: 3px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
}