.wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper h1 {
    text-align: center;
    margin: 0 0;
    color: rgb(105, 104, 104);
    user-select: none;
}

.filtersBlock {
    display: flex;
    width: 100%;
    align-items: center;
}

.filtersBlock > * + * {
    margin-left: 20px;
}

.selectBlock {
    display: flex;
}

.selectBlock > div + div {
    margin-left: 20px;
}

.year span {
    font-size: 18px;
    font-weight: 500;
    color: rgb(83, 83, 83);
    /* text-decoration: underline; */
}

.year select {
    padding: 3px 2px;
    margin-left: 3px;
    font-size: 18px;
    font-weight: 500;
    /* font-style: italic; */
    background-color: transparent;  
    border: none;
    outline: none;
    color: rgb(136, 17, 17);
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.year select:hover {
    transform: scale(1.1);
}

.year span {
    user-select: none;
}

.filter {
    font-size: 14px;
    font-weight: 500;
    color: rgb(136, 17, 17);
}

.filter select {
    padding: 3px 2px;
    margin-left: 3px;
    background-color: transparent;  
    border: none;
    outline: none;
    color: rgb(83, 83, 83);
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.filter select:hover {
    transform: scale(1.1);
}

.filter div {
    user-select: none;
}

.tableWrap, .newTable {
    margin-top: 50px;
    max-width: 90vw;
    max-height: 75vh;
    overflow-x: auto;
    overflow-y: scroll;
    position: relative;
}

.tableWrap table, .newTable table {
    border-collapse: collapse;
    background-color: rgba(240, 248, 255, 0.486);
}

.tableWrap thead, .newTable thead {
    position: sticky;
    user-select: none;
    top: 0;
    z-index: 5;
}

.tableWrap thead th, .newTable thead th {
    background-color: rgb(148, 5, 53);
    color: rgb(245, 162, 107);
    font-size: 13px;
    max-height: 400px;    
}

.newTable thead th, .newTable tbody td {
    font-size: 18px;
}

.tableWrap th, .newTable th {
    padding: 5px;
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 rgb(107, 105, 105), inset 0 -1px 0 rgb(107, 105, 105);
    margin: 1px 0;
    font-weight: 800;
}

.doubleCell {
    width: 142px;
    height: 330px;
    padding: 0;
    margin: 0;
    background: linear-gradient(to bottom left,#0000 49.5%, rgb(107 105 105),#0000 50.5%);
}


.doubleCell>div {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.cellBottomPart {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.cellTopPart {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tableWrap, .newTable {
    user-select: none;
}

.tableWrap tbody, .newTable tbody {
    height: 500px;
    overflow: hidden;
}

.secondWrapStyle {
    height: 70vh;
}

.secondWrapStyle table {
    width: 100%;
}

.secondWrapStyle tr td:last-child {
    position: relative;
}

.secondWrapStyle tr td:last-child img {
    position: absolute;
    top: 5px;
    right: 5px;
}

.tableWrap::-webkit-scrollbar, .newTable::-webkit-scrollbar {
    background-color: rgba(238, 238, 238, 0.562);
    width: .8rem;
    height: .8rem;
  }
  
.tableWrap::-webkit-scrollbar-thumb, .newTable::-webkit-scrollbar-thumb {
    background-color: rgb(95, 95, 95);
    border-radius: 1rem;
}

.tableWrap tbody tr:nth-child(odd), .newTable tbody tr:nth-child(odd) {
    background-color: rgba(247, 0, 255, 0.089);
}

.tableWrap tbody tr:nth-child(even), .newTable tbody tr:nth-child(even) {
    background-color: rgba(0, 255, 255, 0.089);

}

.tableWrap td, .newTable td {
    width: 20px;
    background-color: rgba(240, 248, 255, 0.486);
    position: relative;
    text-align: center;
    font-weight: 500;
    height: 30px;
    cursor: pointer;
    font-size: 13px;
}

.tableWrap tr td:first-child, .newTable tr td:first-child {
    padding: 3px 10px;
}

.tableWrap td:hover {
    background-color: rgba(169, 170, 170, 0.229);
}

.tableWrap td, .tableWrap th, .newTable td, .newTable th {
    border: 1px solid rgb(107, 105, 105);
    -moz-border: 1px solid rgb(107, 105, 105);
    color: rgb(83, 83, 83);
    background-clip: padding-box
}

.newTable tr:nth-child(even):hover, .newTable tr:nth-child(odd):hover {
    background-color: rgba(169, 170, 169, 0.801);
}

.firstColumn {
    width: 90%;
}

.tableWrap input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.rerenderButton {
    margin-top: 20px;
    float: right;
    padding: 10px;
    background-color: rgb(148, 5, 53);
    color: rgb(245, 162, 107);
    border-radius: 4px;
    transition: all 0.2s ease-in;
}

.rerenderButton:hover {
    opacity: 0.7;
}

.closeBtn {
    display: none;
}

.title {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 4px 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}


.modal {
  max-width: 1500px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: initial;
}

.modalBody {
    width: 100%;
    padding: 15px 15px 30px;
}

.paramDescription {
    position: absolute;
    top: -50%;
    right: 50px;
    z-index: 1;
    background-color: #fff798;
    padding: 10px 5px;
    border-radius: 5px;
    box-shadow: 0 0 20px grey;
    display: none;
    transition: all 0.2s ease-in;
}


@media (max-width: 1024px) {
    .tableWrap {
        width: calc(100vw - 2rem);
        overflow: auto;
    }
}


@media (min-width: 768px) {
    .tableBlock {
     width: 80%;   
    }
}