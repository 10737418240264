.wrapper {
    margin: 0 50px 0 10px;
    padding: 2px;
}

.wrapper :hover {
    cursor: pointer;
}

.wrapper div {
    font-size: 12px;
    color: rgb(148, 142, 134);
}

.checkbox {
    display: flex;
    user-select: none;
    width: fit-content;
    color: rgb(85, 83, 83);
    margin: 21px 0 0 0;
    float: right;
    align-items: center;
}

.checkbox input {
    display: none;
}

.fakeBox {
    border: 2px solid rgb(8, 30, 128);
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 0 3px rgb(247, 114, 136);
    justify-content: center;
    align-items: center;
    color: rgb(8, 30, 128);
}

.icon {
    width: 24px;
    height: 24px;
}