.modalBodyBlock {
    display: flex;
    justify-content: space-between;
}

.modalBodyBlock > div {
    width: 49%;
}


.avatar {
    display: flex;
    justify-content: center;
}

.avatar img {
    user-select: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.edit {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.edit:hover {
    cursor: pointer;
}

.editBlock {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
    color: #000;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.editBlock:hover,
.editPhoto:hover {
    cursor: pointer;
}

.photoBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
}

.button {
    width: 100%;
    max-width: 200px;
    font-weight: 400;
    font-size: 1rem;
    background-color: #cc0606;
    color: #fff;
    margin: 1rem auto 0;
    padding: .5rem 1rem;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.input__wrapper {
    width: 100%;
    position: relative;
    margin: 1rem 0 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file_icon_wrapper {
    font-size: 1.3rem;
    height: 50px;
    width: 50px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;
}

.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
}

.input__file_button {
    width: 100%;
    max-width: 200px;
    height: 50px;
    background: #cc0606;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}

.input {
    width: 100%;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 6px;
    box-sizing: border-box;
    resize: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.trash {
    font-size: 1.3rem;
    width: 25px;
    height: 25px;
    margin-right: 1rem;
}

.avatarBlock {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 3px solid white;
    overflow: hidden;
}

.selectTitle {
    font-size: 13px;
    margin-bottom: 5px;
    color: #989898;
}

.departmentSelect {
    margin-bottom: 20px;
    width: 100%;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    padding: 0px 7px;
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 20px;
}

.regionItem {
    display: flex;
}

.regionItem svg {
    cursor: pointer;
}

.depIcon {
    font-size: 20px;
    margin-left: 20px;
}

.depModal {
    width: 300px;
    padding: 20px 10px 20px 20px;
    background-color: white;
    border: 1px solid rgb(167, 162, 162);
    border-radius: 5px;
    position: fixed;
    top: 35%;
    left: 40%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.depModal h4 {
    color: rgb(85, 83, 83);
}

.depList {
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
    margin: 10px 0;
}

.depList::-webkit-scrollbar {
    background-color: #eee;
    width: .5rem;
  }
  
.depList::-webkit-scrollbar-thumb {
    background-color: rgb(95, 95, 95);
    border-radius: 1rem;
}

.notification {
    margin-top: 20px;
    font-style: italic;
    color: brown;
}

.notification svg {
    float: left;
}

.buttonsBlock {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.operationStateBox {
    display: flex;
    justify-content: space-around;
    border: 1px solid #cecece;
    margin-top: 15px;
    padding: 0 10px 10px 10px;
    position: relative;
}

.operationStateBoxTitle {
    font-size: 13px;
    color: #989898;
    padding: 0 10px;
    position: absolute;
    top: -8px;
    left: 20px;
    background-color: white;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
    }

    .mainContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }

    .lines {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }

    .photoBlock {
        align-items: center;
    }

    .editBut {
        right: auto;
        top: -1.5rem;
    }

    .mailImage {
        margin-top: 2rem;
    }

    .avatar {
        margin-bottom: 2rem;
    }
}