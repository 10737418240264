
.awardsBlock {
  position: relative;
  width: min-content;
}

.currentAward {
  width: 100px;
  height: 100px;
  border: 1px solid #cecece;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cecece;
  letter-spacing: -1px;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.awardsList {
  width: 480px;
  height: 320px;
  position: absolute;
  bottom: 0;
  left: 150px;
  border: 1px solid #cecece;
  list-style: none;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  transform: scale(0);
  transform-origin: bottom left;
  transition: .2s ease-in;
  padding: .5rem;
  z-index: 1;

  > li {
    width: 150px;
    height: 150px;
    flex: 0 1 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #cecece;
    letter-spacing: -1px;
    
    &:first-child {
      border: 1px solid #ebebeb7d;
    }
    
    &:hover {
      border: 1px solid #cecece !important;
    }

    > img {
      max-width: 100%;
      max-height: 100%;
    }

  }

  &::-webkit-scrollbar {
    background-color: rgba(238, 238, 238, 0.562);
    width: .8rem;
    height: .8rem;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgb(95, 95, 95);
    border-radius: 1rem;
  }

  > .selected {
    border: 1px solid #f59595 !important;
  }

}

.awardsListRight {
  left: auto;
  right: 150px;
  transform-origin: bottom right;
}

.visibleList {
  transform: scale(1);
}