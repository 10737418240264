.cardWrapper {
    height: 295px;
    width: 450px;
    margin: .7rem;
    box-shadow: 0px 0px 11px #c3c3c3;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    overflow: hidden;
}

.old {
    background-color: rgb(255 208 158);
}

.young {
    background-color: rgba(244 245 239);
}

.cardWrapper a {
    text-decoration: none;
}

.cardWrapper:hover {
    /* transform: scale(1.01); */
    box-shadow: 0px 0px 41px #ababab;
    transition: all 0.2s ease-in;
}

.firstBlock {
    width: 30%;
    padding: 0;
    border-right: 1px solid rgba(97, 97, 97, 0.096);
}

.foto {
    width: 100%;
    height: 150px;
    /* border-right: 1px solid rgba(0, 0, 0, 0.096); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.foto img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.toolBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-color: rgba(49, 49, 49, 0.637);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px;
    animation: opacity 0.5s ease-in;
}


.toolBar svg {
    color: white;
    font-size: 24px;
}

.icons {
    width: 100%;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 0.3rem 0;
}

.icon:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in;
}

.icon div {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
}

.secondBlock {
    width: 70%;
    border-top: 1px solid rgba(0, 0, 0, 0.096);
    align-items: center;
}


.name {
    height: 52px;
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(170, 4, 4);
    border-bottom: 1px solid rgba(97, 97, 97, 0.096);
    position: relative;
    padding-top: 0.3rem;
    padding-left: 5px;
    overflow: hidden;
}

.birthday {
    font-weight: 600;
    color: #9d0a0e;
    font-size: 14px;
    padding-left: .5rem;
}

.name div {
    position: absolute;
    bottom: 16px;
    right: 5px;
    color: rgb(85, 83, 83);
}

.department {
    border-bottom: 1px solid rgba(97, 97, 97, 0.096);
    padding: .5rem .5rem .5rem 5px;
    height: 160px;
    overflow-y: auto;
}

.department::-webkit-scrollbar, .regionList::-webkit-scrollbar {
    width: .4rem;
  }
  
.department::-webkit-scrollbar-track, .regionList::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
}

.department::-webkit-scrollbar-thumb, .regionList::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
}

.depItem {
    font-size: 14px;
}

.depItem + .depItem {
    margin-top: 5px;
}

.depItem span {
    color: rgb(170, 4, 4);
    font-weight: 800;
    font-size: 14px;
}

.description {
    padding: .5rem 5px 0 5px;
    font-size: 13px;
    position: relative;
}

.description div {
    color: rgb(82, 80, 80);
    display: flex;
    align-items: center;
    padding: 2px 0;
}

.description b {
    margin-right: 3px;
}

.description img {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
}

@keyframes opacity {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 768px) {
    .name {
        font-size: .9rem;
    }

    .icon {
        width: 90px;
    }

    .cardWrapper {
        margin: .7rem 0;
    }

    .description div {
        font-size: 14px;
    }
}

/* @media (max-width: 1024px) {

    .cardWrapper {
        height: auto;
        width: 100%;
        max-width: 49%;
        min-width: 300px;
        margin: 10px 0;
    }
}

@media (max-width: 682px) {
    .cardWrapper {
        margin: 1rem;
        max-width: 100%;
        min-width: auto;
    }

    .foto {
        width: 100px;
        height: 100px;
    }

    .name {
        font-size: 1.2rem;
        padding: .5rem;
    }

    .descriptBlock {
        width: 100%;
    }

    .firstBlock {
        flex-direction: column;
    }

    .foto { 
        margin: 0 auto;
    }

    .secondBlock span{ 
        font-size: 1rem;
    }


} */