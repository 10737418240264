.container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.header {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    padding: 0.5rem auto;
}

.header > a:first-child {
    font-size: 1.5rem;
    text-decoration: none;
    position: absolute;
    top: 0.5rem;
    right: .5rem;
    color: rgb(94, 138, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
}

.header > a:first-child:hover {
    color: rgb(48, 87, 87);
}

.header > a:first-child div {
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 5px;
    color: brown;
}

.header > a:first-child:hover div {
    text-decoration: underline;
}

.image {
    top: 40px;
    left: 50px;
    height: 130px;
    pointer-events: none;
    animation: logoAuth 4s ease-in infinite;
}

.imageAuth {
    position: absolute;
    top: 40px;
    left: 50px;
    height: 250px;
    pointer-events: none;
    animation: logo 5s ease-in infinite;
}

.titles {
    margin-left: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preTitle {
    color: brown;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.title {
    margin-top: 15px;
    color: brown;
    font-size: 26px;
    font-weight: 400;
    max-width: 700px;
    text-align: center;
}

/* .mainContent {
    display: flex;
    align-items: center;
    height: 140px;
}

.avatar {
    max-height: 100%;
    position: relative;
}

.avatar span {
    position: absolute;
    top: 0;
    right: 0;
}

.avatar div {
    max-height: 140px;
}

.avatar img {
    max-height: 140px;
} */

.burgerMenu {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
    display: none;
}

.menu {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.menuItem+.menuItem .item {
    border-left: 2px solid rgb(206, 206, 206);
    text-transform: uppercase;
}

.menuItem+.menuItem .item.active {
    border-left: 2px solid #fad8aa;
}

.menuItem {
    width: calc(100% / 9);
    position: relative;
}

.item {
    color: rgb(134, 134, 134);
    font-weight: 500;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
}

.item a {
    text-decoration: none;
    color: rgb(134, 134, 134);
}

.item.active {
    cursor: pointer;
    background-color: #fad8aa;
    color: brown;
}

.menuBox {
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    width: 100%;
}

.menuBox a {
    text-decoration: none;
    word-break: break-word;
}

.iteem {
    padding: .8rem .5rem;
    background-color: #f2c689;
    color: brown;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.iteem:hover,
.childrenLinksContainer:hover > .iteemSub:hover {
    cursor: pointer;
    color: rgb(199, 33, 33);
    background-color: #f1b877;
}

.iteemSub {
    font-size: 12px;
    position: relative;
    display: none;
}

.childrenLinksContainer:hover > .iteemSub {
    display: block;
    background-color: #ebdbc4;
}

.exitMob {
    color: rgb(134, 173, 173) !important;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.1s ease-in;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: none;
}

.exit {
    color: rgb(134, 173, 173) !important;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.exit:hover {
    color: rgb(110, 146, 146) !important;
}

.wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    padding: 0 1rem 90px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(242, 238, 222);
    box-shadow: 0 0 10px rgb(173, 173, 173);
}

.version {
    position: absolute;
    top: 12px;
    left: 10px;
    color: rgba(92, 92, 92, 0.61);
    font-size: 14px;
}

.footerText {
    font-size: 13px;
    font-weight: 500;
    color: rgba(92, 92, 92, 0.61);
}

.mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    /* width: 30%; */
    position: relative;
}

.lines {
    position: relative;
    /* width: 277px; */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.numbers {
    text-decoration: underline;
    cursor: pointer;
}

.editBut {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
}

.editBut:hover {
    cursor: pointer;
}

.lines div {
    color: brown;
    font-weight: 500;
    /* line-height: 35px; */
}

.avatar img {
    user-select: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.mailImage {
    position: relative;
}

.mailImage img {
    cursor: pointer;
}

.circle {
    height: 20px;
    width: 20px;
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 50%;
    background-color: brown;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailImage img {
    width: 45px;
}

.edit {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.edit:hover {
    cursor: pointer;
}

.editBlock {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
    color: #000;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.mobTames {
    color: #7f0711;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #7f0711;
}

.mobInfoBlock {
    display: none;
}

.editBlock:hover,
.editPhoto:hover {
    cursor: pointer;
}

.photoBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 110px;
}

.button {
    width: 100%;
    max-width: 200px;
    height: 20px;
    font-weight: 400;
    font-size: 10px;
    background-color: #cc0606;
    color: #fff;
    margin: 2px auto 0;
    padding: .5rem 0;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.input__wrapper {
    width: 100%;
    position: relative;
    margin: 1px 0 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file_icon_wrapper {
    font-size: 1.2rem;
    /* height: 50px;
    width: 50px; */
    margin-left: .5rem;
    margin-right: .5rem;
    padding-right: .5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;
}

.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
    font-size: 11px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: .3rem;
}

.delTxt {
    font-size: 11px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: .3rem;
}

.input__file_button {
    width: 100%;
    max-width: 200px;
    height: 20px;
    font-weight: 400;
    font-size: 10px;
    background: #cc0606;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}

.input {
    width: 100%;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 6px;
    box-sizing: border-box;
    resize: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.trash {
    font-size: 1.2rem;
    margin-left: .5rem;
    margin-right: .5rem;
    padding-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;
}

.avatarBlock {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    border: 3px solid #7f0711;
    overflow: hidden;
}

.avatarBlockMob {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    border: 3px solid #7f0711;
    overflow: hidden;
}

@media (max-width: 1700px) {
    .item {
        padding: 3px 15px;
    }
}

@media (max-width: 1200px) {
    .imageAuth {
        position: inherit;
        top: 40px;
        left: 50px;
        height: 130px;
        pointer-events: none;
    }
}

@media (max-width: 980px) {
    .item {
        padding: 2px 1px;
    }

    .title {
        text-align: left;
    }

    .mainContent {
        margin-right: 1rem;
        margin-left: 0px;
    }

    
    .header > a:first-child {
        top: 0.1rem;
        right: .5rem;
    }
}

@media (max-width: 1000px) {

    .item.active {
        background-color: #fad8aa;
        border-bottom: 1px solid brown;
        transition: .5s;
    }

    .menu {
        justify-content: flex-start;
        padding-top: 15vh;
    }

    .menuBox {
        border-bottom: 1px solid brown;
    }

    .burgerMenu {
        z-index: 100;
    }

    .item {
        color: brown;
        font-size: 17px;
    }

    .item a {
        color: brown;
    }

    .container {
        width: 100%;
        min-width: 0;
        position: relative;
    }

    .header {
        width: 100%;
        justify-content: space-between;
        padding: 1.5rem;
    }

    .image {
        margin-right: 50px;
    }

    .imageAuth {
        position: absolute;
        top: 20px;
        left: 10px;
        height: 110px;
        pointer-events: none;
    }

    .preTitle {
        display: none;
    }

    .title {
        display: none;
    }

    .burgerMenu {
        display: block;
    }

    .menu {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        background-color: rgb(246 195 135);
        height: 100vh;
        flex-direction: column;
        overflow-y: auto;
        z-index: 99;
        flex-wrap: nowrap;
        overflow: auto;
        margin-top: 0;
        padding-top: 64px;
    }

    .menu.active {
        left: 0;
        transition: all ease-in 0.2s;
    }

    .item {
        padding: 1rem;
    }

    .wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        padding: 1rem;
    }

    .footer {
        display: none;
    }

    .menuItem {
        width: 100%;
    }

    .menuBox {
        position: relative;
        top: 0;
    }

    .exit {
        right: 0;
        bottom: -60px;
        padding: 1rem 0;
        font-size: 20px;
        line-height: 10px;
    }

    .content {
        display: none;
    }

    .exitMob {
        display: block;
    }

    /* .avatarBlockMob {
        display: block;
    }

    .mobTames {
        display: block;
    } */
    .mobInfoBlock {
        display: block;
    }

    .header > a:first-child {
        bottom: 1rem; 
        right: 1.5rem;
    }
}

@media (max-width: 1000px) {
    .content {
        width: 100%;
    }

    .mainContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }

    .lines {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }

    .photoBlock {
        display: block;
        margin: 0 auto;
    }

    .editBut {
        right: auto;
        top: -1.5rem;
    }

    .mailImage {
        margin-top: 2rem;
    }

    .avatar {
        margin: 0 auto;
        width: 110px;
        position: relative;
    }
}

@keyframes logo {
    0%, 100% {
        transform: rotate(0);
    }
    47% {
        transform: rotate(0);
    }
    49% {
        transform: rotate(-5deg);
    }
    51% {
        transform: rotate(5deg);
    }
    53% {
        transform: rotate(0);
    }
}

@keyframes logoAuth {
    0%, 100% {
        opacity: 1;
    }
    50% {
        transform: scale(0.96);
        opacity: 0.7;
    }
}


@media (max-width: 1000px) {
    .header > a:first-child {
        top: auto;
        right: 1rem;
    }
}