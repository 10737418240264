.wrapper {
    width: 100%;
    padding: 10px;
}

.controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-right: 2rem;
}

.controls > div {
    min-width: 350px;
    margin: .5rem;
}

.controls > svg {
    position: absolute;
    top: 1.8rem;
    right: 5%;
    color: #fa0c00;
}

.controls > svg:hover {
    transform: scale(1.05);
}

.firstSelect select {
    border-radius: .2rem;
    font-size: 15px;
    padding: 8px 8px;
    border: 1px solid rgb(201, 199, 199);
    max-width: 100%;
}

.firstSelect select:focus {
    outline: 2px solid #4091fa;
    
}

.tableBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tableBlock table {
    border-collapse: collapse;
    margin: 1rem auto;
}

.tableBlock tr {
    cursor: pointer;
}

.tableBlock td, .tableBlock th {
    border: 1px solid rgb(80, 79, 79);
    padding: 3px;
    color: rgb(80, 79, 79);
}

.tableBlock th {
    background-color: rgba(240, 248, 255, 0.693);
}

.tableBlock td {
    text-align: center;
    background-color: rgba(127, 255, 212, 0.337);
}

.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(80, 79, 79);
}

.tableWrapper {
    width: 95vw;
    overflow-x: auto;
}

.detailsRow {
    cursor: default !important;
}

.detailsRow td {
    background-color: rgb(251 237 217);
    font-style: italic;
    font-weight: 500;
    color: rgb(127 7 17);
}

@media (max-width: 1650px) {
    .controls > svg {
        right: 1rem;
    }
}