
.form {
    margin-top: 25px;
    /* height: 120px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.form > * + * {
    margin-top: 1rem;
}

.form input {
    height: 27px;
    width: 250px;
    padding-left: 5px;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 3px;
    color: brown;
    font-weight: 700;
}

.form input:focus {
    outline: none;
    border: 1px solid rgb(150, 150, 150);
}

.form button {
    height: 27px;
    width: 100px;
    background-color: rgb(196, 37, 37);
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
}

.form button:hover {
    opacity: 0.7;
}

.form button[disabled] {
    opacity: 0.7;
}

.inputBlock > p {
    color: red;
    font-size: .85rem;
    line-height: .9;
    margin-left: 0.2rem;
}

@media (max-width: 768px) {
    .form {
        width: 100%;
        height: 160px;
    }
    .form input {
        width: 100%;
        height: 40px;
        font-size: 18px;
    }
    .form button {
        height: 40px;
        width: 50%;
    }
}