.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}
  


.tableHeader div {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
  cursor: pointer;
}

.tableRow > div {
  color: #5b5c61;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.firstTd {
  flex-basis: 3%;
}

.displayname {
  cursor: pointer;
  flex-basis: 36%;
}

.displayname span {
  position: relative;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.tableRow:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.tableRow:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.thirdTd {
  flex-basis: 24%;
}

.fourthTd {
  flex-basis: 24%;
}

.superuser {
  flex-basis: 12%;
  margin-left: .5rem;
  /* color: #4c4c4c;
  font-size: 15px;
  font-weight: 700; */
}

.actions {
  flex-basis: 5%;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.closeBtn {
  display: none;
}

.modal {
  max-width: 720px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: initial;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.modalBody div:first-child > div {
  margin-top: 8px;
}

.downloadExcel {
  background-color: rgb(117, 194, 181);
  width: fit-content;
  max-width: 190px;
  color: #fff;
  padding: .5rem 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .2rem;
  cursor: pointer;
}

.downloadExcel .excelLogo {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.excelLoad {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin .5s linear infinite;
  margin-left: .5rem;
}

.mapBlock {
  margin-top: 15px;
  width: 100%;
}

.mapBlock > span {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.input {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.fileItem + .fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

  
.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1rem 0;
  padding: .5rem;
  border: 1px solid rgba(0,0,0,.5);
  border-radius: .2rem;
}

.imagePDF {
  color: red;
  font-size: 30px;
  height: 30px;
  width: 30px;
  margin: 0 .5rem 0 0;
}

.titlePDF {
  font-size: 16px;
}

.linkpdf {
  display: contents;
  color: #000;
}

.toTrash {
  color: red;
  font-size: 25px;
  width: 25px;
  height: 25px;
  margin: 0 0 0 3rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}