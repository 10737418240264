@font-face {
    font-family: 'FuturaFuturisBlackC';
    src: local('FuturaFuturisBlackC'), url('../fonts/FuturaFuturisBlackC.ttf') format('truetype');
  }

@font-face {
font-family: 'FuturaFuturisCBold';
src: local('FuturaFuturisCBold'), url('../fonts/FuturaFuturisC-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCBoldItalic';
    src: local('FuturaFuturisCBoldItalic'), url('../fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCItalic';
    src: local('FuturaFuturisCItalic'), url('../fonts/FuturaFuturisC-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisC';
    src: local('FuturaFuturisC'), url('../fonts/FuturaFuturisC.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCameoExtraBoldC';
    src: local('FuturaFuturisCameoExtraBoldC'), url('../fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #f8e9cf;
}

.content {
    width: 100%;
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
    padding: 6vw 4vw 9vw 4vw;
    z-index: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.title {
    color: #f8e9cf;
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 6vw;
    font-family: 'FuturaFuturisC';
}

.wrapperImg {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.mainImg {
    width: 50%;
    margin: 0 0 0 auto;
}

.wrapperText {
    margin-top: 3vw;
    margin-bottom: 1vw;
    height: 28vh;
    overflow-y: auto;
}

.wrapperText_item {
    color: #9d0a0e;
    font-size: 2.5vw;
    font-weight: 500;
    margin-bottom: 1vw;
    font-family: 'FuturaFuturisC';
}

.wrapperImg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4vw;
}

.img {
    width: 47%;
}

.wrapperText::-webkit-scrollbar, .regionList::-webkit-scrollbar {
    width: .4rem;
  }
  
.wrapperText::-webkit-scrollbar-track, .regionList::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
}

.wrapperText::-webkit-scrollbar-thumb, .regionList::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
}

.buttonBack {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    width: 10vw;
    z-index: 3;
}

.figure_1 {
    background: linear-gradient( 0deg, rgb(157,10,14) 0%, rgb(112,0,0) 100%);
    width: 110vw;
    height: 25vh;
    position: absolute;
    top: -9%;
    left: -10%;
    z-index: 2;
    transform: rotate(-10deg);
}

.figure_2 {
    background: linear-gradient( 0deg, rgb(157,10,14) 0%, rgb(112,0,0) 100%);
    width: 110vw;
    height: 25vh;
    position: absolute;
    bottom: -5%;
    right: -10%;
    z-index: 2;
    transform: rotate(-10deg);
}



