.description {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.356);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in;
}


.description div {
    width: 1000px;
    max-height: 400px;
    background-color: white;
    overflow-y: auto;
    user-select: none !important;
    border-radius: 15px;
    border: 2px solid black;
    padding: 10px;position: relative;
}

.description div::-webkit-scrollbar {
    width: 0;
}

.description div svg {
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 20px;
    color: grey;
    cursor: pointer;
}

.description div svg:hover {
    color: rgb(54, 146, 252);
}