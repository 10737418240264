.container {
    position: relative;
    max-width: 1200px;
    width: 100%;
  }
  
  .title {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
  }
  
  .newsList {
      width: 100%;
      min-height: 523px;
      height: calc(100% - 34px);
      padding: 0px 14px 70px;
      background-color: #fff;
      position: relative;
  }
  
  .newsListHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
  }
  
  .tableHeader {
      display: flex;
      align-items: center;
      min-height: 36px;
      border-bottom: 1px solid #c4c4c4;
      font-size: 15px;
    }
    
  
  
    .tableHeader div:first-child {
      color: #4c4c4c;
      font-size: 15px;
      font-weight: 700;
    }
  
    .tableRow {
      padding: 3px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8d5d5;
      cursor: pointer;
      font-size: 15px;
    }
  
    .tableRow:hover {
      background-color: #fcf6f6b4;
    }
    
    .displayname {
      color: #5b5c61;
      cursor: pointer;
      flex-basis: 8%;
      margin-right: 0.5rem;
    }
  
    .displayname:hover {
      color: #5871c5;
    }
    
    .username {
      flex-basis: 18%;
    }
    
    .superuser {
      flex-basis: 44%;
      overflow-wrap: anywhere;
    }

    .report_count {
      flex-basis: 8%;
    }

    .report_comment {
      flex-basis: 10%;
    }

    .report_likes {
      flex-basis: 8%;
    }
    
    .actions {
      flex-basis: 13%;
    }

    .data {
        flex-basis: 10%;
        text-align: right;
    }
  
    .actions svg {
      color: rgb(204, 20, 20);
      cursor: pointer;
      font-size: 24px;
    }
  
    .actions svg:hover {
      color: #ff0000;
    }
  
    .deleteIcon {
      width: 20px;
      cursor: pointer;
    }
  
    .pagination {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .itemsCount {
      position: absolute;
      bottom: 25px;
      left: 25px;
    }
    
    .itemsCount select {
      color: grey;
      cursor: pointer;
      outline: none;
      border-radius: 3px;
    }
    
    .itemsCount span {
      margin-left: 5px;
      color: grey;
    }
  
    /*==============================MODAL===================================*/
  
  
    .closeBtn {
      display: none;
    }
    
    .modal {
      max-width: 700px;
      width: 100%;
      height: auto;
      padding: 0;
      background-color: #fff;
      overflow: hidden;
    }
  
    .modalBody {
        width: 100%;
        padding: 15px 15px 30px;
    }

    .modalBody video {
      margin-top: 10px;
    }
  
    .modalMain {
  
    }
  
  .input {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
  }
  
  .select {
      width: 90%;
      font-size: 18px;
      font-weight: 500;
  }
  
  .filesBlock {
      margin-top: 15px;
      width: 90%;
      word-break: break-all;
  }
  
  .fileItem {
      display: flex;
      padding-left: 10px;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px dashed rgb(119, 118, 118);
  }
  
  .fileItem:hover {
    border: 1px solid rgb(119, 118, 118);
  }
  
  .fileItem a {
      width: 90%;
  }
  
  .fileItem + .fileItem {
      margin-top: 5px;
  }
  
  .imgBlock {
      margin: 4px 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;
  }
  
  .imgBlock img {
      max-width: 100%;
      max-height: 100%;
  }
  
  .input {
      margin-top: 10px;
  }
  
    .modalButtons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  
    .modalButtons button {
      width: 125px;
      height: 30px;
      margin: 0 20px;
      font-size: 16px;
      border-radius: 25px;
    }
  
    @media (max-width: 1024px) {
        .newsListHeader {
            flex-wrap: wrap;
        }

        .selectBlock select {
            margin-bottom: .5rem;
            width: 100%;
        }

        .dateBlock {
            margin-top: .3rem;
        }

        .newsList {
            padding: 0px 14px 140px;
        }

        .tableRow {
            font-size: .9rem;
        }
    }
    @media (max-width: 425px) {
      .title {
        font-size: 1rem;
      }
    
      .pagination {
        width: calc(100% - 2rem);
      }
    }

    @media (max-width: 600px) {
        .superuser {
            display: none;
        }
        .username {
      flex-basis: 30%;
    }
    
    .superuser {
      flex-basis: 54%;
    }

    .username {
        flex-basis: 50%;
      }
  
    .data {
        flex-basis: 45%;
        text-align: right;
    }

    .displayname {
        flex-basis: 40%;
      }
    }


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
  }
  
  .switch input {
    display:none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .switch input:disabled + .slider {
    opacity: 0.5;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: brown;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px brown;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
  }

  .wrapperSliderEquipment {
    width: 100%;
    max-width: 400px;
  }
  
  .equipment__small .photoWrapper {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  
  .deletePhoto {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-size: 28px;
    z-index: 9;
  }
  
  .wrapperSliderEquipmentBig img {
    height: 400px;
    object-fit: contain;
  }
  
  .wrapperSliderEquipmentSmall img {
    height: 100px;
    object-fit: contain;
  }
  
  .load {
    position: relative;
  }

.dateBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

  .dateBlock input {
    width: 80px;
    font-size: .8rem;
    margin-right: .2rem;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
  }

  .dateBlock > div > div  {
    font-size: .9rem;
    font-weight: 500;
    color: #000;
  }

  .selectBlock {
    display: flex;
    flex-direction: column;
  }

  .selectBlock > input {
    width: 260px;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    padding-left: .2rem;
  }

.selectBlock:last-child {
    /* width: 250px; */
}

  .selectBlock select {
    max-width: 170px;
    height: 24px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
  }

  .selectBlock span {
    margin-right: .5rem;
    font-size: .9rem;
    font-weight: 500;
  }

  .blockOpen {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }