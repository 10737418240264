

.modal {

  width: 600px;
  min-height: 300px;
  padding: 0;

  > img {
    width: 100%;
  }

  > .descriptionBlock {
    padding: 1.5rem;
    
    > h2 {
      color: #911f1f;
      text-align: center;
    }
  
    > .descriptionList {
      list-style: none;
      margin-top: .5rem;

      > li {
        padding: .1rem 0;

        > span {
          color: #c71a1a;
          margin-right: .2rem;
          font-weight: 500;
        }
      }
    }

    > .description {
      > h2 {
        color: #911f1f;
        margin: .5rem 0;
      }
    }
  }

  > h1 {
    text-align: center;
    color: #911f1f;
    margin-top: 130px;
  }
}