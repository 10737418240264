.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .pagination li:first-child {
    margin-right: 15px;
  }
  
  .pagination li:last-child {
    margin-left: 15px;
  }
  
  .pagination li {
    min-width: 20px;
    list-style-type: none;
  }
  
  .pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #989898;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    padding: 1rem .2rem;
  }

  .pagination li a:hover {
    color: #d24a43;
    transition: color .3s ease-in-out; 
   }
  
  .pagination li.active a {
    color: #d24a43;
    border-bottom: 2px solid #d24a43;
  }

  @media (max-width: 682px) {
    
    .pagination {
      flex-wrap: wrap;
    }
}


