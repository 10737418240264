.container {
  position: relative;
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}

.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
}

.displayname {
  color: #5871c5;
  cursor: pointer;
  flex-basis: 80%;
}

.deleteIcon {
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}

.date {
  /* font-size: 15px; */
  font-weight: 500;
  margin: .5rem;
  flex-basis: 10%;
}

.duration {
  flex-basis: 10%;
  font-style: italic;
}


.closeBtn {
  display: none;
}

.modal {
  max-width: 480px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.input {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.fileBlock>p {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin: 7px 0 4px 0;
}


.fileItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in;
  width: 100%;
}

.fileItem>svg {
  color: red;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  animation: appearance 0.2s ease-in;
}

.fileItem>svg:first-child {
  font-size: 22px;
  right: 25px;
  color: #203372;
}

.fileItem>svg:hover {
  transform: scale(1.2);
}

.filePreview {
  width: 100%;
  height: 300px;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgb(201, 200, 200);
  transition: all 0.2s ease-in;
  overflow: hidden;
}

.filePreview img {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreview button {
  position: absolute;
  bottom: 50px;
  left: 35%;
  width: 30%;
  background-color: rgba(128, 128, 128, 0.715);
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.2s ease-in;
  display: none;
  animation: appearance 0.2s ease-in;
}

.filePreview button:hover {
  opacity: 0.8;
}

.fileTitle {
  max-width: 90%;
  margin-top: 5px;
  word-break: break-all;
  text-align: center;
  color: #203372;
  transition: all 0.2s ease-in;
}

.fileItem:hover .filePreview {
  box-shadow: 0 0 10px rgb(116, 115, 115);
  transform: scale(1.005);
}

.fileItem:hover>svg {
  display: block;
  z-index: 1;
}

.fileItem:hover button {
  display: block;
}

.fileItem:hover .fileTitle {
  text-decoration: underline;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 0;
  padding: .5rem .8rem;
  border-radius: .2rem;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  background-color: red;
  color: #fff;
  width: fit-content;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

@media (max-width: 678px) {
  .displayname {
    width: 100%;
  }

  .tableRow {
    flex-wrap: wrap;
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}


.videoTitle {
  font-size: .9rem;
  padding: 1rem 0 .4rem;
  display: block;
  font-weight: 500;
  word-break: break-word;
}

.videoTime {
  font-size: .9rem;
  font-weight: 500;
  padding: 0 0 .4rem;
}

.videoW {
  margin-right: .3rem;
}

.videoInfoWrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #eee;
  border-radius: .3rem;
  padding: .5rem;
  width: fit-content;
}

.videoInfoWrapperDisable {
  border: none;
}

.descr {
  padding-bottom: .5rem;
  display: block;
}

.categoriesLists {
  width: 100%;
  max-width: 100%;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 1rem;
}

.categoriesLists::-webkit-scrollbar {
  width: .5rem;
}

.categoriesLists::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 1rem;
}

.categoriesLists::-webkit-scrollbar-thumb {
  background-color: rgb(117, 194, 181);
  border-radius: 1rem;
}

.categoriesTitleMain {
  margin: .5rem 0;
  display: block;
}

.categoriesListItem {
  border: 1px solid #ccc;
  border-radius: .2rem;
  margin: .3rem 0;
}

.wrapperInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.wrapperInput input {
  font-size: 1rem;
  padding: .4rem;
  margin-right: 1rem;
  border-radius: 1px solid #ccc;
}

.buttonAdd {
  padding: .5rem 1rem;
  background-color: rgb(117, 194, 181);
  color: #fff;
  cursor: pointer;
  border-radius: .2rem;
}

.categoriesWrpapper {
  margin: 1rem 0;
}

.inputWrapper {
  display: flex;
  align-items: center;
  padding: .3rem 0;
}

.inputWrapper input {
  margin-right: .3rem;
}

/* для элемента input c type="radio" */
.customRadio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}

/* для элемента label связанного с .customRadio */
.customRadio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding: .2rem .5rem;
  cursor: pointer;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.customRadio+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.customRadio:checked+label::before {
  box-shadow: 0 0 0 0.2rem rgba(117, 194, 181, .25);
  border-color: rgb(117, 194, 181);
  background-color: rgb(117, 194, 181);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.customRadio:disabled+label::before {
  background-color: #e9ecef;
}

.radio {
  margin-bottom: 0.3em;
  width: fit-content;
}

.radio:hover .customRadio:not(:disabled):not(:checked)+label:hover::before {
  cursor: pointer;
  border-color: rgb(117, 194, 181);
}

.wrapperDropzone {
  width: 100%;
}

.selectBlock {
  display: flex;
  flex-direction: column;
}

.selectBlock > input {
  width: 260px;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding-left: .2rem;
}

@media (max-width: 768px) {

  .itemsCount {
    position: static;
    bottom: auto;
    left: auto;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}